<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')" class="cur">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="banner">
      <img src="../../public/imgs/news/bannerImg.png" alt="" />
    </div>
    <div class="main">
      <div class="title">行业资讯</div>
      <el-row class="elRowClass">
        <el-col :span="16" class="elColClass" @click.native="jumpNew('1')">
          <div>办公室装饰的特点和技巧!</div>
          <div>
            办公室装饰是一个比较烦琐的工程，尤其要注意细节。在设计上，可以充分利用办公室的所有空间进行办公。使得办公设计更具特色，从而使员工在工作中感受到温馨，让工作效率更大程度的提高，提升公司的形象
          </div>
          <div>2021-02-22</div>
        </el-col>
        <el-col :span="8" class="elColImgClass elColImgClass1">
          <img src="../../public/imgs/news/newImg.png" alt="" />
        </el-col>
      </el-row>
      <el-row class="elRowClass elRowClass2">
        <el-col :span="16" class="elColClass" @click.native="jumpNew('2')">
          <div>建筑设计中玻璃幕墙的作用!</div>
          <div>
            <p>（1）调节建筑物采光度。</p>
            <p>（2）满足建筑物通风的需求。</p>
            <p>（3）扩展视野。</p>
          </div>
          <div>2021-03-21</div>
        </el-col>
        <el-col :span="8" class="elColImgClass">
          <img src="../../public/imgs/news/newImg2.png" alt="" />
        </el-col>
      </el-row>
      <el-row class="elRowClass">
        <el-col :span="16" class="elColClass" @click.native="jumpNew('3')">
          <div>会议室装修预算怎么样做</div>
          <div>
            会议室装修请专业团队是非常有必要的，除了环境本身面积大小要考虑，还要结合会议室的实际功能来确定。请专业团队，做出合理设计，确保专业施工，这样就可以建立起专业实用的基础。这其中突出实用性的重点，要有功能的实用性考虑，会议室装修预算合理是关键。
          </div>
          <div>2021-05-18</div>
        </el-col>
        <el-col :span="8" class="elColImgClass">
          <img src="../../public/imgs/news/newImg3.png" alt="" />
        </el-col>
      </el-row>
      <el-row class="elRowClass">
        <el-col :span="16" class="elColClass" @click.native="jumpNew('4')">
          <div>软木地板在办公室中要若何保养？</div>
          <div>
            办公室软木地板应该怎么照顾护士？软木地板也遍及应用于办公室装修，具有环保、隔音、防潮的浸染，在市场上很受接待。你知道如何照顾护士软木地板吗？
          </div>
          <div>2021-07-11</div>
        </el-col>
        <el-col :span="8" class="elColImgClass">
          <img src="../../public/imgs/news/newImg4.png" alt="" />
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    jumpNew(index) {
      if (index == 1) {
        this.$router.push({
          path: "/newsItem1",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/newsItem2",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsItem3",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/newsItem4",
        });
      }
    },
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsInfo",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.banner {
  width: 100%;
  height: 602px;
  background: url("../../public/imgs/news/banner.png") no-repeat center center;
  background-size: 100% 100%;
  overflow: hidden;
  margin-top: 100px;
}
.banner img {
  display: block;
  width: 598px;
  margin: 260px auto;
}
.main {
  width: 1200px;
  height: 1370px;
  margin: 80px auto;
}
.title {
  width: 96px;
  background: #333;
  padding: 4px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  color: #ffffff;
}
.elRowClass {
  margin-top: 26px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 40px;
  cursor: pointer;
}
.elColClass > div:nth-of-type(1) {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  margin-top: 45px;
  margin-bottom: 18px;
  transition: color 0.5s;
}
.elColClass > div:nth-of-type(2) {
  font-size: 14px;
  color: #666;
  margin-bottom: 40px;
  line-height: 30px;
}
.elColClass > div:nth-of-type(3) {
  font-size: 14px;
  font-weight: 600;
  color: #666666;
}
.elColImgClass {
  background: url("../../public/imgs/news/border.png") no-repeat 50px center;
}
.elColImgClass img {
  width: 338px;
  margin-top: 50px;
  margin-left: 62px;
}
.elColImgClass1 img {
  margin-top: 25px;
}

.elColClass:hover div:nth-of-type(1) {
  color: #fab767;
}
</style>
